<template>
  <base-material-card color="drawerColorSecondary">
    <template v-slot:heading>
      <div class="display-2 font-weight-light">Solicitudes Pendientes</div>
    </template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        ID
                    </th>
                    <th class="text-left">
                        Nombre
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in listaDeSolicitudes"
                :key="item.idCuenta"
                @click="$emit('usuarioElegido',item.idCuenta.toString())"
                >
                <td>{{ item.idCuenta }}</td>
                <td>{{ item.usuario }}</td>
                </tr>
            </tbody>
    </template>

    </v-simple-table>
  </base-material-card>
</template>

<script>

export default {
    data() {
        return {
            listaDeSolicitudes: [],
        }
    },
    sockets: {
        actualizaPendientes(data) {
            this.cargaSolicitudes();
        },
        nuevaSolicitud(data){
            this.cargaSolicitudes();
        }
    },
    mounted() {
      this.cargaSolicitudes();
   },
   methods: {
      async cargaSolicitudes() {
        // console.log(this.$store.state.cuenta.token);
        const r = await fetch(process.env.VUE_APP_URL_BACKEND + 
              '/administrador/prestamos/lista/pendientes',
              {
                method: "GET" ,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + this.$store.state.cuenta.token
                }
            });
        this.listaDeSolicitudes = await r.json();
        //console.log(this.listaDeSolicitudes);
      }
   }

}
</script>

<style>

</style>